import { render, staticRenderFns } from "./PlcItemDetail.vue?vue&type=template&id=bd91d198&scoped=true"
import script from "./PlcItemDetail.vue?vue&type=script&lang=js"
export * from "./PlcItemDetail.vue?vue&type=script&lang=js"
import style0 from "./PlcItemDetail.vue?vue&type=style&index=0&id=bd91d198&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bd91d198",
  null
  
)

export default component.exports